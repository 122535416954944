<template>
    <div class="ticket">
        <center>
            <img :src="'/logo/logo_brayan4.png'" alt="Logo" style="width:50px;height:50px;margin-bottom:15px">
        </center>
        <div class="centered">STRUK BARANG KELUAR
        </div>
        
        -------------------------------------------------
        <table style="border:0px">
            <tbody style="border:0px">
                <tr style="border:0px">
                    <td style="border:0px"> Tujuan: {{ print_value.tujuan }}</td>
                </tr>
                <tr style="border:0px">
                    <td style="border:0px"> Keterangan: {{ print_value.keterangan }}</td>
                </tr>
            </tbody>
        </table>
    -------------------------------------------------
        <div style="margin-top:4px" v-for="(p1, index) in print_value.detail_notapemindahan" :key="index">
    
            <div style="margin-top:4px">{{ p1.barang.nama_barang.toUpperCase()}} ( {{ "x"+p1.qty }} )</div>
    
            <table style="border:0px;margin-top:5px">
                <tbody style="border:0px">
                    <tr style="border:0px">
                        <td class="quantity" style="border:0px"></td>
                    </tr>
                </tbody>
            </table>
    
        </div>
        -------------------------------------------------
        
    
        <br>
        <p class="centered">{{ filterdate(print_value.created_at) }}<br>Thanks for your purchase!
            <br>Brayan Diesel  </p>
                
        <button id="btnPrint" class="hidden-print btn btn-primary text-white" @click="printed">Print</button>
    </div>
    </template>
    
    <script>
    import axios from 'axios'
    import moment from 'moment';
    export default {
        data() {
            return {
                //state loggedIn with localStorage
                loggedIn: localStorage.getItem('loggedIn'),
                //state token
                token: localStorage.getItem('token'),
                //state user logged In
                print_value: [],
                jumtransaksi: 0,
                jumpendapatan: 0,
                diskon: 0,
                edcNominal: 0,
                link: this.globalApiUrl,
            }
        },
        created() {
            if (!this.loggedIn) {
                if (!this.token) {
                    localStorage.removeItem("loggedIn");
                    localStorage.removeItem("token");
                    return this.$router.push({
                        name: 'login.index'
                    })
                } else {
                    localStorage.removeItem("loggedIn");
                    localStorage.removeItem("token");
                }
            } else {
                console.log(this.token);
                let data = this.$route.query.data;
                axios.get(this.link + 'api/barang_keluar_print?data=' + data, {
                    headers: {
                        'Authorization': this.token
                    }
                }).then(response => {
                    console.log(response);
                    this.print_value = response.data.data;
                    console.log(this.print_value);
                    // assign response to state user
                }).catch(error => {
                    window.opener = self;
                    window.close();
                    setTimeout(function () {
                        window.close();
                    }, 800);
                });
    
            }
    
        },
        mounted() {
            // print();
        },
        methods: {
            printed() {
                print();
            },
            filterdate(a) {
                // console.log(a);
                return moment(String(a)).format('h:mm:ss DD/MM/YYYY')
            },
            
        },
    }
    </script>
    
    <style>
    * {
        font-size: 10px !important;
        font-family: 'arial' !important;
        line-height: normal !important;
        /* font-weight:normal; */
    }
    
    body {
        color: #000000 !important;
        padding-top: 10px !important;
    
    }
    
    td,
    th,
    tr,
    table {
        border-top: 1px solid black;
        border-collapse: collapse;
    }
    
    td.description,
    th.description {
        width: 95px;
        max-width: 95px;
    }
    
    td.quantity,
    th.quantity {
        width: 26px;
        max-width: 26px;
        font-size: 9.3px !important;
        word-break: break-all;
    }
    
    td.price1,
    th.price1 {
        width: 75px;
        max-width: 75px;
        font-size: 9.3px !important;
        word-break: break-all;
    }
    
    td.price,
    th.price {
        width: 75px;
        max-width: 75px;
        font-size: 9.3px !important;
        word-break: break-all;
    }
    
    .centered {
        text-align: center;
        align-content: center;
    }
    
    .ticket {
        /* margin-left: 4px; */
        width: 170px;
        max-width: 170px;
    
    }
    
    img {
        max-width: inherit;
        width: inherit;
    }
    
    @media print {
    
        .hidden-print,
        .hidden-print * {
            display: none !important;
        }
    }
    </style>
    